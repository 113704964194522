<template>
    <TransitionRoot as="template" id="test1" :show="isListResultModalOpen">
        <Dialog as="div" auto-reopen="true" class="fixed z-10 inset-0 overflow-y-auto" @close="this.closeListResultModal()">
          <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
              <DialogOverlay class="fixed inset-0 dark:bg-true-gray-900 dark:bg-opacity-75 bg-warm-gray-900 bg-opacity-75" />
            </TransitionChild>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div class="inline-block align-bottom dark:bg-true-gray-800  bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all w-full sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">
                <!-- Close Button -->
                <div class="block absolute top-0 right-0 pt-4 pr-4">
                  <button type="button" class="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none" @click="this.closeListResultModal()">
                    <span class="sr-only">Close</span>
                    <XIcon class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                
                
                <div class="relative px-4 sm:px-6 lg:px-8 max-w-4xl mx-auto py-4 mt-4">

                    <span class="block text-base text-center dark:text-yellow-300 text-yellow-600 font-semibold tracking-wide uppercase mb-10">
                        {{ openedSubject.subjectName }}
                    </span>

                    <h2 class="mb-6 font-semibold tracking-wider text-2xl dark:text-white">Exam Results</h2>
                    <!-- no result view -->
                    <div v-if="openedSubject.results.length == 0" class=" border border-dashed rounded-md p-5 border-warm-gray-600 flex flex-col justify-center items-center">
                      <p class=" text-warm-gray-500">No result has been added yet.</p>
                    </div>

                    <!-- list of exams results -->
                    <div v-else class=" flex flex-col gap-4">

                      <!-- PC -->
                      <div v-for="(examResult,i) in openedSubject.results" :key="i" class=" hidden md:flex justify-between items-center border border-warm-gray-400 dark:border-warm-gray-600 bg-true-gray-200 dark:bg-true-gray-700 w-full p-3 rounded">
                        <div>
                          <p class=" text-sm text-warm-gray-500">score:</p>
                          <h3 class=" font-semibold text-xl" :class="examResult.data.isPassed ? 'text-green-700':'text-red-700'">{{examResult.data.score}}%</h3>
                        </div>

                        <div>
                          <p class=" text-sm text-warm-gray-500">take:</p>
                          <h3 class="text-warm-gray-600 dark:text-warm-gray-300 font-semibold text-xl">{{ getOrdinal(examResult.data.takeCount) }}</h3>
                        </div>

                        <div>
                          <p class=" text-sm text-warm-gray-500">Date:</p>
                          <h3 class="text-warm-gray-600 dark:text-warm-gray-300 font-semibold text-xl">{{ formatDate(examResult.data.examDate.seconds) }}</h3>
                        </div>

                        <div v-if="isEditable" class=" flex justify-center items-center pt-2">
                          <button v-if="openedSubject.results.length - 1 == i" @click="deleteExamResultModal(examResult)" class=" p-2 rounded-md text-red-800 hover:text-red-700 items-center">
                            <TrashIcon class=" w-5 h-5"/>
                          </button>
                          <div v-else class=" p-2 rounded-md text-red-800 items-center opacity-30">
                            <TrashIcon class=" w-5 h-5"/>
                          </div>
                          <button @click="updateExamResultModal(examResult)" class=" p-2 rounded-md text-yellow-800 hover:text-yellow-700 items-center">
                            <PencilAltIcon class=" w-5 h-5"/>
                          </button>
                        </div>
                      </div>

                      <!-- Mobile -->
                      <div v-for="(examResult,i) in openedSubject.results" :key="examResult.take" class=" md:hidden border border-warm-gray-400 dark:border-warm-gray-600 bg-true-gray-200 dark:bg-true-gray-700 w-full p-3 rounded">
                        <div>
                          <p class=" text-sm text-warm-gray-500">score:</p>
                          <h3 class=" font-semibold text-xl" :class="examResult.data.isPassed ? 'text-green-700':'text-red-700'">{{examResult.data.score}}%</h3>
                        </div>

                        <div>
                          <p class=" text-sm text-warm-gray-500">take:</p>
                          <h3 class=" text-warm-gray-600 dark:text-warm-gray-300 font-semibold text-xl">{{ getOrdinal(examResult.data.takeCount) }}</h3>
                        </div>

                        <div>
                          <p class=" text-sm text-warm-gray-500">Date:</p>
                          <h3 class=" text-warm-gray-600 dark:text-warm-gray-300 font-semibold text-xl">{{ formatDate(examResult.data.examDate.seconds) }}</h3>
                        </div>

                        <div v-if="isEditable" class=" flex justify-center items-center pt-2">
                          <button v-if="openedSubject.results.length - 1 == i" @click="deleteExamResultModal(examResult)" class=" p-2 rounded-md text-red-700 flex justify-center items-center bg-true-gray-600 dark:bg-true-gray-800 w-full border border-true-gray-600">
                            <TrashIcon  class=" w-4 h-4 mr-1"/>
                            Delete
                          </button>
                          <button @click="updateExamResultModal(examResult)" class=" p-2 rounded-md text-yellow-700 flex justify-center items-center bg-true-gray-600 dark:bg-true-gray-800 w-full border border-true-gray-600">
                            <PencilAltIcon  class=" w-4 h-4 mr-1"/>
                            Update
                          </button>
                        </div>
                      </div>

                    </div>

                    <button
                      @click="openNewExamResultModal" 
                      class="disabled:bg-gray-400 mt-8 text-white bg-indigo-600 w-full py-3 border border-transparent rounded-md shadow-sm px-4 inline-flex justify-center items-center text-xl font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-30 disabled:pointer-events-none disabled:cursor-auto"
                      :disabled="!isEditable"
                      >
                      <PlusIcon class=" h-5 w-5 mr-1" />
                      <span>Add new result</span>
                    </button>
                  
                </div>
              </div>
            </TransitionChild>
          </div>
        </Dialog>
      </TransitionRoot>
  </template>
   
  <script>
  import firebase from "@/firebase/config";
  const db = firebase.firestore()

  import { XIcon, PencilAltIcon, PlusIcon, TrashIcon } from "@heroicons/vue/solid";
  import { formatDate } from '@/utils/dateHelper.js'
  
  import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
  } from "@headlessui/vue";
  export default {
    props: ["isListResultModalOpen","openedSubject","claimantsInfo","isKTRUploaded"],
    data(){
        return{
            step: 1,
        }
    },
    components: {
      XIcon,
      PencilAltIcon,
      PlusIcon,
      TrashIcon,
      TransitionRoot,
      TransitionChild,
      Dialog,
      DialogOverlay,
      DialogTitle,
    },
    computed: {
      isEditable() {
        if (this.isKTRUploaded) {
          return this.claimantsInfo && this.claimantsInfo.postExamSurveyInfoStatus === 'rejected';
        }else{

          if (this.claimantsInfo) {
            return this.claimantsInfo.postExamSurveyInfoStatus === 'rejected';
          }else{
            return true;
          }

        }
      }
    },
    mounted(){
      // console.log('opened subject =>',this.openedSubject);
    },
    methods: {
      formatDate,
      closeListResultModal() {
        this.$emit("closeListResultModal");
      },
      openNewExamResultModal(){
        this.$emit("openNewExamResultModal")
      },
      updateExamResultModal(result){
        this.$emit("updateResult", result)
      },
      deleteExamResultModal(result){
        this.isDeleting = true;
        console.log("deleting exam result...", result);
        let confirmDelete = confirm("Are you sure to delete this exam result?")
        if (confirmDelete) {
          db.collection("exam_results").doc(result.id).delete().then(() => {
              console.log("Result successfully deleted!");
              this.$emit('fetchExamResults');
              this.closeListResultModal();
              this.isDeleting = false;
            }).catch((error) => {
              console.error("Error deleting result: ", error);
              this.isDeleting = false;
          });
        }

      },
      getOrdinal(number){
        // Handle special cases for 11th, 12th, and 13th
        if (number % 100 >= 11 && number % 100 <= 13) {
            return number + "th";
        }

        // Handle all other cases
        switch (number % 10) {
            case 1:
                return number + "st";
            case 2:
                return number + "nd";
            case 3:
                return number + "rd";
            default:
                return number + "th";
        }
      }
    },
  };
  </script>