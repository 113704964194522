<template>
    <TransitionRoot as="template" id="test1" :show="isUploadKTRModalOpen">
        <Dialog as="div" auto-reopen="true" class="fixed z-10 inset-0 overflow-y-auto" @close="this.closeUploadKTRModal()">
          <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
              <DialogOverlay class="fixed inset-0 dark:bg-true-gray-900 dark:bg-opacity-75 bg-warm-gray-900 bg-opacity-75" />
            </TransitionChild>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div class="inline-block align-bottom dark:bg-true-gray-800  bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all w-full sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">
                <!-- Close Button -->
                <div class="block absolute top-0 right-0 pt-4 pr-4">
                  <button type="button" class="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none" @click="this.closeUploadKTRModal()">
                    <span class="sr-only">Close</span>
                    <XIcon class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                
                
                <div class="relative px-4 sm:px-6 lg:px-8 max-w-4xl mx-auto py-4 mt-4">

                    <span class="block text-base text-center dark:text-yellow-300 text-yellow-600 font-semibold tracking-wide uppercase mb-10">
                      Upload KTR
                    </span>

                    <div class="flex flex-col space-y-2 text-xl dark:text-gray-400 text-gray-600 my-8">
                      <p>We will need you to upload a clear copy/picture of your official CAAP Knowledge Test Report. This will help us verify the validity of your entry, and will act as your proof of passing the exam.</p>
                      <div class="bg-yellow-100 border-t-4 border-yellow-500 rounded-b text-yellow-900 px-4 py-3 shadow-md" role="alert">
                        <div class="flex">
                          <svg class="fill-current flex-shrink-0 min-w-6 h-6 w-6 text-yellow-500 mr-2" viewBox="0 0 24 24"><g class="warning-outline"><g fill="currentColor" class="Vector"><path fill-rule="evenodd" d="M12 13.8a1 1 0 0 1-1-1v-5a1 1 0 0 1 2 0v5a1 1 0 0 1-1 1" clip-rule="evenodd"/><path d="M10.947 15.958a1.053 1.053 0 1 1 2.106 0a1.053 1.053 0 0 1-2.106 0"/><path fill-rule="evenodd" d="m15.607 4.642l5.876 10.72c1.512 2.759-.473 6.138-3.607 6.138H6.124c-3.134 0-5.12-3.38-3.607-6.139l5.876-10.72c1.566-2.855 5.648-2.855 7.214 0Zm-1.804 1c-.782-1.429-2.824-1.429-3.606 0L4.32 16.36c-.757 1.38.236 3.069 1.803 3.069h11.752c1.567 0 2.56-1.69 1.803-3.07z" clip-rule="evenodd"/></g></g></svg>
                          <div class="text-sm space-y-2">
                            <p>Before you upload, make sure that the “Rating” (grades) found in your certificate matches the entries you’ve made in your personal CAAP Exam Checklist. Failing to do so will invalidate this whole submission.</p>
                            <p>Once you upload your KTR, you will not be able to change the scores entered above.</p>
                          </div>
                        </div>
                      </div>
                      <!-- <span @click="isSampleModalOpen = true" class="underline text-base text-blue-700 cursor-pointer">Click here to see sample</span> -->
                      <a href="https://firebasestorage.googleapis.com/v0/b/skilltech-prod.appspot.com/o/images%2Fmockups%2Fsample-caap-test-result.jpg?alt=media&token=9bf0dfec-b7eb-4656-ab6d-9c65cb261e3d" target="_blank" rel="noopener noreferrer" class="underline text-base text-blue-700 cursor-pointer">Click here to see sample</a>
                    </div>
                    
                    <div class=" border border-dashed rounded-md p-5 border-warm-gray-600 flex flex-col justify-center items-center">

                      <div v-if="uploadedKTRs && uploadedKTRs.length >  0" class="rounded-md p-5 flex justify-start items-end">
                        <img v-if="!imagePreview" :src="uploadedKTRs[uploadedKTRs.length - 1].url" class=" h-24 w-auto">
                        <img v-else :src="imagePreview" class=" h-24 w-auto">
                      </div>
                      <p v-else class=" text-warm-gray-500">
                        <span v-if="!imagePreview">No KTR has been uploaded yet.</span>
                        <img v-else :src="imagePreview" class=" h-24 w-auto">
                      </p>

                      <div class="mt-4 shadow-sm bg-warm-gray-600  focus:ring-gray-500 focus:border-gray-500 block w-full sm:text-sm border-gray-600 border-2 rounded-lg">
                          <input id="test_results" @change="someHandler" name="test_results" type="file" accept="application/pdf,image/jpeg,image/png" class="p-4 inset-0 opacity-0 w-full cursor-pointer rounded-md" />
                          <label for="test_results" :class="!test_results ? 'justify-center' : ' justify-between'" class="flex text-sm text-gray-200 pointer-events-none w-full p-4 -mt-14">
                            <span v-if="!test_results">Upload your Test Results here (accepts PDF, JPEG, PNG; file size below 2MB)</span>
                            <span v-else>Selected: {{test_results}}  </span>
                            <span v-if="test_results" class="right-0 text-sm font-medium text-red-700 dark:text-red-400">Change</span>
                          </label>
                      </div>

                    </div>


                   
                    <button
                      @click="handleClickUploadButton()"
                      class="disabled:bg-gray-400 mt-4 text-white bg-indigo-600 w-full py-3 border border-transparent rounded-md shadow-sm px-4 inline-flex justify-center items-center text-xl font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-30 disabled:pointer-events-none disabled:cursor-auto"
                      :disabled="!test_results || !tmpFile || isUploading || uploadedKTRs.length > 4"
                      >
                      <UploadIcon class=" h-5 w-5 mr-1" />
                      <span>{{ isUploading ? 'Uploading...' : 'Upload' }}</span>
                    </button>
                  
                </div>
              </div>
            </TransitionChild>
          </div>
        </Dialog>
      </TransitionRoot>
  </template>
   
  <script>
  import firebase from "@/firebase/config";
  const db = firebase.firestore();

  import { XIcon, PencilAltIcon, PlusIcon, TrashIcon } from "@heroicons/vue/solid";
  import { UploadIcon } from '@heroicons/vue/outline';
  import { formatDate } from '@/utils/dateHelper.js'
  
  import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
  } from "@headlessui/vue";
  export default {
    props: ["isUploadKTRModalOpen","uploadedKTRs"],
    data(){
        return{
            step: 1,
            isUploading: false,
            isSampleModalOpen: false,
            test_results: null,
            tmpFile: null,
            imagePreview: null,
            authUserId: null,
        }
    },
    components: {
      XIcon,
      PencilAltIcon,
      PlusIcon,
      TrashIcon,
      UploadIcon,
      TransitionRoot,
      TransitionChild,
      Dialog,
      DialogOverlay,
      DialogTitle,
    },
    mounted(){
      this.authUserId = firebase.auth().currentUser.uid;
    },
    methods: {
      formatDate,
      closeUploadKTRModal() {
        this.test_results = null;
        this.tmpFile = null;
        this.$emit("closeUploadKTRModal");
      },
      getOrdinal(number){
        // Handle special cases for 11th, 12th, and 13th
        if (number % 100 >= 11 && number % 100 <= 13) {
            return number + "th";
        }

        // Handle all other cases
        switch (number % 10) {
            case 1:
                return number + "st";
            case 2:
                return number + "nd";
            case 3:
                return number + "rd";
            default:
                return number + "th";
        }
      },
      // someHandler(event) {
      //   console.log("User updated the file")
        
      //   var filesize = ((event.target.files.item(0).size/1024)/1024).toFixed(4); // MB
        
      //   console.log(`File size: ${filesize}MB`)
  
      //   if(filesize > 2){
      //       alert("File size of 2MB exceeded")
      //       // this.emptyFile()
      //       this.test_results = null 
      //   }else{
      //       this.test_results = event.target.files.item(0).name
      //       this.tmpFile = event.target.files.item(0)
      //       this.selectedImage = URL.createObjectURL(file);
      //       // console.log(event)
      //   }
        
      // },
      someHandler(event) {
        console.log("User updated the file");

        const file = event.target.files.item(0);
        const filesize = ((file.size / 1024) / 1024).toFixed(4); // MB

        console.log(`File size: ${filesize}MB`);

        if (filesize > 2) {
          alert("File size of 2MB exceeded");
          this.test_results = null;
          this.tmpFile = null;
          this.imagePreview = null; // Clear preview if file is invalid
        } else {
          this.test_results = file.name;
          this.tmpFile = file;

          // ✅ Use URL.createObjectURL() for an image preview
          if (file.type.startsWith("image/")) {
            if (this.imagePreview) {
              URL.revokeObjectURL(this.imagePreview); // Free memory from the previous URL
            }
            this.imagePreview = URL.createObjectURL(file);
          } else {
            this.imagePreview = null; // No preview for non-image files
          }
        }
      },
      async handleClickUploadButton(){
        if (!this.test_results || !this.tmpFile) {
          return;
        }
        this.isUploading = true;
        const res = await this.saveTestResult();
        if (res) {

          const payload = {
            ktrs: [
              ...(this.uploadedKTRs ? this.uploadedKTRs : []),
              {
                url: res,
                // uploadedAt: firebase.firestore.FieldValue.serverTimestamp(),
                uploadedAt: new Date(),
                filenameBeforeUpload: this.test_results
              }
            ]
          }

          db.collection("exam_result_ktrs").doc(this.authUserId).set(payload)
          .then(() => {
              alert("KTR have been successfully uploaded.");
              this.$emit("handleNewUploadKTR");
              this.closeUploadKTRModal();
          })
          .catch((error) => {
            alert("Unable to save KTR. Please try again later.");
            this.isUploading = false;
          });
          

        }else{
          alert("Unable to upload KTR. Please try again later.");
          this.isUploading = false;
        }


      },
      async saveTestResult() {
        try {
            let storageRef = firebase.storage().ref();
            const timestamp = Date.now();
            let testResultImagesRef = storageRef.child(`testresults_new/${this.authUserId}_${timestamp}.jpg`);
            let resultUrl = null;

            if (this.tmpFile) {
                const snapshot = await testResultImagesRef.put(this.tmpFile);
                resultUrl = await snapshot.ref.getDownloadURL();
            }

            return resultUrl;
        } catch (error) {
            console.error("Error uploading test result:", error);
            return null; // Handle error gracefully
        }
      }

    },
  };
  </script>