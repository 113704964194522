<template>
    <div>
        <section>
            <h1>Skilltech App Privacy Policy</h1>
            <h2>Last Updated: <em>February 19, 2025</em></h2>
            <dl v-for="(term, index) in terms" :key="`term-${index}`">
                <Disclosure as="div" v-slot="{ open }">
                    <dt class="text-lg">
                        <DisclosureButton class="text-left w-full flex justify-between items-start text-gray-400">
                            <span class="font-medium text-warm-gray-900 dark:text-true-gray-300">
                                {{ `${index+1}. ${term.title}` }}
                            </span>
                            <span class="ml-6 h-7 flex items-center">
                                <ChevronDownIcon :class="[open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform']"
                                    aria-hidden="true" />
                            </span>
                        </DisclosureButton>
                    </dt>
                    <DisclosurePanel as="dd" class="mt-2 pr-12">
                        <p v-for="content in term.contents" v-html="content"></p>
                    </DisclosurePanel>
                </Disclosure>
            </dl>
        </section>
    </div>
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/outline'

export default {
    components: {
        Disclosure,
        DisclosureButton,
        DisclosurePanel,
        ChevronDownIcon,
    },
    created() {
        // console.log("Adding overflow-hidden")
        document.body.classList.remove("overflow-hidden");
    },
    data() {
        return {
            terms: [
                {
                    title: `Introduction`,
                    contents: [
                        `Welcome to <strong>Skilltech</strong>!`,
                        `Thank you for choosing to use <strong>Skilltech App</strong>, a software platform provided by <strong>Skilltech Studios Inc.</strong> (“Skilltech”, “we”, “our”, or “us”), located at Unit 707, Avida One Park Drive, 11th Drive cor. 9th Ave., Bonifacio Global City, Taguig, NCR, 1635, Philippines. This software platform (the “Platform” or “App”) is designed to assist graduates and students in their journey toward becoming professionals by offering a comprehensive review for licensing exams and other related services.`,
                        `Our Privacy Policy governs your visit and use of the Platform, and explains how we collect, safeguard and disclose information that results from your use of our Service.`,
                        `We use your data to provide and improve the Platform. By using our App, <strong>Users</strong> (referred to herein as “Users”, “User”, “you”) agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.`,
                        `Our Terms and Conditions (“<strong>Terms</strong>”) govern all use of our Service and together with the Privacy Policy constitutes your agreement with us (“<strong>Agreement</strong>”)`,
                    ]
                },
                {
                    title: `Information We Collect`,
                    contents: [
                        `While using our App, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“Personal Data”). Personally identifiable information may include, but is not limited to:`,
                        `<ul class="list-disc pl-4">
                            <li>Email address</li>
                            <li>First name, middle name and last name</li>
                            <li>Mobile number</li>
                            <li>Address, Country, State, Province, ZIP/Postal code, City</li>
                            <li>School, Course and School Year</li>
                            <li>License Exam Results</li>
                            <li>Cookies and Usage Data</li>
                        </ul>`,
                        `While using our Platform, we may also collect the following information: sex, age, date of birth, place of birth, passport details, citizenship, registration at place of residence and actual address, telephone number (work, mobile), details of documents on education, qualification, professional training, employment agreements, NDA agreements, information on bonuses and compensation, information on marital status, family members, social security (or other taxpayer identification) number, office location and other data.`
                    ]
                },
                {
                    title: `Using Your Personal Information`,
                    contents: [
                        `Personal information submitted to us through our software will be used for the purposes specified in this policy or on the relevant pages of the software. We may use your personal information to:`,
                        `<ul class="list-disc pl-4">
                            <li>Administer our Platform and business.</li>
                            <li>Personalize our Platform for you.</li>
                            <li>Enable your use of the services available on our Platform.</li>
                            <li>Send statements, invoices, and payment reminders to you, and collect payments from you.</li>
                            <li>Send you non-marketing commercial communications.</li>
                            <li>Send you email notifications that you have specifically requested.</li>
                            <li>Provide third parties with statistical information about our users (but those third parties will not be able to identify any individual user from that information).</li>
                            <li>Send newsletters, marketing or promotional materials and other information that may be of interest to you.</li>
                        </ul>`,
                        `You may opt out of receiving some of these communications from us by following an unsubscribe link that is sent within the email.`,
                    ]
                },
                {
                    title: `Disclosing Personal Information`,
                    contents: [
                        `We may disclose your personal information to any of our employees, officers, insurers, professional advisers, agents, suppliers, or subcontractors as reasonably necessary for the purposes set out in this policy.`,
                        `We may disclose your personal information:`,
                        `<ul class="list-disc pl-4">
                            <li>To the extent that we are required to do so by law.</li>
                            <li>In connection with any ongoing or prospective legal proceedings.</li>
                            <li>In order to establish, exercise, or defend our legal rights (including providing information to others for the purposes of fraud prevention and reducing credit risk).</li>
                        </ul>`,
                    ]
                },
                {
                    title: `Test Results and Rewards`,
                    contents: [
                        `When you submit your test results to claim a reward through the App, you agree to the following:`,
                        `(a) Handling of Test Results: We will handle your test results confidentially. Your test results will be used solely for the purpose of determining your eligibility for rewards and will not be shared with third parties, except as outlined in this policy or as required by law.`,
                        `(b) Sharing of Test Results: By submitting your test results, you grant us the permission to share your test results publicly on the Platform and our social media channels. Your test results will be shared in accordance with your indicated preferences at the time of submission. You may revoke this permission at any time by contacting us, but this will not affect any sharing that has already occurred.`,
                        `(c) Accuracy of Test Results: You are responsible for ensuring the accuracy and authenticity of the test results you submit to us. We are not responsible for verifying the accuracy of test results submitted by users.`,
                        `(d) Rewards: Rewards are granted at our sole discretion. Submitting test results does not guarantee that you will receive a reward.`,
                        `Please ensure that you are comfortable with these terms before submitting your test results to claim rewards. If you have any questions or concerns about how your test results will be handled, please contact us.`,
                    ]
                },
                {
                    title: `Security of Your Personal Information`,
                    contents: [
                        `We will take reasonable technical and organizational precautions to prevent the loss, misuse, or alteration of your personal information. All electronic financial transactions entered into through our software will be protected by encryption technology.`,
                    ]
                },
                {
                    title: `Usage Data`,
                    contents: [
                        `We may also collect information that your browser sends whenever you visit our App or when you access our Platform by or through any device (“Usage Data”).`,
                        `This Usage Data may include information such as your computer’s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Platform that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.`,
                        `When you access the App with a device, this Usage Data may include information such as the type of device you use, your device unique ID, the IP address of your device, your device operating system, the type of Internet browser you use, unique device identifiers and other diagnostic data.`,
                    ]
                },
                {
                    title: `Location Data`,
                    contents: [
                        `We may use and store information about your location if you give us permission to do so (“Location Data”). We use this data to provide features of our Service, to improve and customize our Service. You can enable or disable location services when you use our Service at any time by way of your device settings.`,
                    ]
                },
                {
                    title: `Cookies`,
                    contents: [
                        `We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information. Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect and track information and to improve and analyze our Service.`,
                        `You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.`,
                        `Examples of Cookies we use:`,
                        `<ul class="list-disc pl-4">
                            <li><strong>Session Cookies:</strong> We use Session Cookies to operate our Service.</li>
                            <li><strong>Preference Cookies:</strong> We use Preference Cookies to remember your preferences and various settings.</li>
                            <li><strong>Security Cookies:</strong> We use Security Cookies for security purposes.</li>
                            <li><strong>Advertising Cookies:</strong> Advertising Cookies are used to serve you with advertisements that may be relevant to you and your interests.</li>
                        </ul>`,
                    ]
                },
                {
                    title: `Analytics`,
                    contents: [
                        `We may use third-party Service Providers to monitor and analyze the use of our Service.`,
                    ]
                },
                {
                    title: `CI/CD Tools`,
                    contents: [
                        `We may use third-party Service Providers to automate the development process of our Service.`,
                    ]
                },
                {
                    title: `Amendments`,
                    contents: [
                        `We may update this policy from time to time by publishing a new version on our software. You should check this page occasionally to ensure you understand any changes to this policy.`,
                    ]
                },
                {
                    title: `Your Rights`,
                    contents: [
                        `You may instruct us to provide you with any personal information we hold about you. Provision of such information will be subject to the supply of appropriate evidence of your identity.`,
                    ]
                },
                {
                    title: `Third Party Websites`,
                    contents: [
                        `Our software includes hyperlinks to, and details of, third-party websites. We have no control over and are not responsible for the privacy policies and practices of third parties.`,
                    ]
                },
                {
                    title: `Payments`,
                    contents: [
                        `We may provide paid products and/or services within the Platform. In that case, we use third-party services for payment processing (e.g. payment processors). We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.`,
                    ]
                },
                {
                    title: `Children’s Privacy`,
                    contents: [
                        `Our Platform is not intended for use by children under the age of 18 (“Child” or “Children”).`,
                        `We do not knowingly collect personally identifiable information from Children under 18. If you become aware that a Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from Children without verification of parental consent, we take steps to remove that information from our servers.`,
                    ]
                },
                {
                    title: `Updating Information`,
                    contents: [
                        `Please let us know by contacting us via email if the personal information that we hold about you needs to be corrected or updated.`,
                    ]
                },
                {
                    title: `Retention of Data`,
                    contents: [
                       `We will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.`,
                       `We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.`,
                    ]
                },
                {
                    title: `Transfer of Data`,
                    contents: [
                        `Your information, including Personal Data, may be transferred to – and maintained on – computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.`,
                        `If you are located outside the Philippines and choose to provide information to us, please note that we transfer the data, including Personal Data, to the Philippines and process it there.`,
                        `Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.`,
                        `Skilltech Inc. will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.`,
                    ]
                },
                {
                    title: `Changes to This Privacy Policy`,
                    contents: [
                        `We reserve the right to modify our Privacy Policy at any time. It is your responsibility to review our Privacy Policy page periodically. Your continued use of the Platform following the posting of any changes to the Privacy Policy constitutes acceptance of those changes.`,
                        `By continuing to access or use our App after any revisions become effective, you agree to be bound by the revised Privacy Policy. If you do not agree to the new Privacy Policy, you are no longer authorized to use the App.`,
                    ]
                },
                {
                    title: `Contact`,
                    contents: [
                        `If you have any questions about this Privacy Policy or our treatment of your personal information, please write to us by email to support@skilltech.ph.`,
                    ]
                },

                // {
                //     title: `Introduction`,
                //     contents: [
                //         `Welcome to <strong>Skilltech</strong>!`,
                //         `Skilltech Inc. ("<strong>Skilltech Inc."</strong>, <strong>"we"</strong>, <strong>"our"</strong>, or <strong>"us"</strong>) operates Skilltech, a software platform (referred to herein as the <strong>"Platform"</strong> or <strong>"App"</strong>) which designed to assist graduates (and/or students) on their journey towards being professionals by offering a comprehensive review for licensing exams among other services.`,
                //         `Our Privacy Policy governs your visit and use of the Platform, and explains how we collect, safeguard and disclose information that results from your use of our Service.`,
                //         `We use your data to provide and improve the Platform. By using our App, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.`,
                //         `Our Terms and Conditions (<strong>"Terms"</strong>) govern all use of our Service and together with the Privacy Policy constitutes your agreement with us (<strong>"Agreement"</strong>).`,
                //     ]
                // },
                // {
                //     title: `Information We Collect`,
                //     contents: [
                //         `While using our App, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“Personal Data”). Personally identifiable information may include, but is not limited to:`,
                //         `<ul class="list-disc pl-4">
                //             <li>Email address</li>
                //             <li>First name, middle name and last name</li>
                //             <li>Mobile number</li>
                //             <li>Address, Country, State, Province, ZIP/Postal code, City</li>
                //             <li>School, Course and School Year</li>
                //             <li>License Exam Results</li>
                //             <li>Cookies and Usage Data</li>
                //         </ul>`,
                //         `While using our Platform, we may also collect the following information: sex, age, date of birth, place of birth, passport details, citizenship, registration at place of residence and actual address, telephone number (work, mobile), details of documents on education, qualification, professional training, employment agreements, NDA agreements, information on bonuses and compensation, information on marital status, family members, social security (or other taxpayer identification) number, office location and other data.`
                //     ]
                // },
                // {
                //     title: `Using Your Personal Information`,
                //     contents: [
                //         `Personal information submitted to us through our software will be used for the purposes specified in this policy or on the relevant pages of the software. We may use your personal information to:`,
                //         `<ul class="list-disc pl-4">
                //             <li>Administer our Platform and business.</li>
                //             <li>Personalize our Platform for you.</li>
                //             <li>Enable your use of the services available on our Platform.</li>
                //             <li>Send statements, invoices, and payment reminders to you, and collect payments from you.</li>
                //             <li>Send you non-marketing commercial communications.</li>
                //             <li>Send you email notifications that you have specifically requested.</li>
                //             <li>Provide third parties with statistical information about our users (but those third parties will not be able to identify any individual user from that information).</li>
                //             <li>Send newsletters, marketing or promotional materials and other information that may be of interest to you. </li>
                //         </ul>`,
                //         `You may opt out of receiving some of these communications from us by following an unsubscribe link that is sent within the email.`
                //     ]
                // },
                // {
                //     title: `Disclosing Personal Information`,
                //     contents: [
                //         `We may disclose your personal information to any of our employees, officers, insurers, professional advisers, agents, suppliers, or subcontractors as reasonably necessary for the purposes set out in this policy.`,
                //         `We may disclose your personal information:`,
                //         `<ul class="list-disc pl-4">
                //             <li>To the extent that we are required to do so by law.</li>
                //             <li>In connection with any ongoing or prospective legal proceedings.</li>
                //             <li>In order to establish, exercise, or defend our legal rights (including providing information to others for the purposes of fraud prevention and reducing credit risk).</li>
                //         </ul>`,
                //     ],
                // },
                // {
                //     title: `Test Results and Rewards`,
                //     contents: [
                //         `When you submit your test results to claim a reward through the App, you agree to the following:`,
                //         `<strong>(a) Handling of Test Results:</strong> We will handle your test results confidentially. Your test results will be used solely for the purpose of determining your eligibility for rewards and will not be shared with third parties, except as outlined in this policy or as required by law.`,
                //         `<strong>(b) Sharing of Test Results:</strong> By submitting your test results, you grant us the permission to share your test results publicly on the Platform and our social media channels. Your test results will be shared in accordance with your indicated preferences at the time of submission. You may revoke this permission at any time by contacting us, but this will not affect any sharing that has already occurred.`,
                //         `<strong>(c) Accuracy of Test Results:</strong> You are responsible for ensuring the accuracy and authenticity of the test results you submit to us. We are not responsible for verifying the accuracy of test results submitted by users.`,
                //         `<strong>(d) Rewards:</strong> Rewards are granted at our sole discretion. Submitting test results does not guarantee that you will receive a reward.`,
                //         `Please ensure that you are comfortable with these terms before submitting your test results to claim rewards. If you have any questions or concerns about how your test results will be handled, please contact us.`,
                //     ]
                // },
                // {
                //     title: `Security of Your Personal Information`,
                //     contents: [
                //         `We will take reasonable technical and organizational precautions to prevent the loss, misuse, or alteration of your personal information. All electronic financial transactions entered into through our software will be protected by encryption technology.`
                //     ]
                // },
                // {
                //     title: `Usage Data`,
                //     contents: [
                //         `We may also collect information that your browser sends whenever you visit our App or when you access our Platform by or through any device (“Usage Data”).`,
                //         `This Usage Data may include information such as your computer’s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Platform that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.`,
                //         `When you access the App with a device, this Usage Data may include information such as the type of device you use, your device unique ID, the IP address of your device, your device operating system, the type of Internet browser you use, unique device identifiers and other diagnostic data.`,
                //     ]
                // },
                // {
                //     title: `Location Data`,
                //     contents: [
                //         `We may use and store information about your location if you give us permission to do so (“Location Data”). We use this data to provide features of our Service, to improve and customize our Service. You can enable or disable location services when you use our Service at any time by way of your device settings.`
                //     ]
                // },
                // {
                //     title: `Cookies`,
                //     contents: [
                //         `We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information. Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect and track information and to improve and analyze our Service.`,
                //         `You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.`,
                //         `Examples of Cookies we use:`,
                //         `<ul class="list-disc pl-4">
                //             <li><strong>Session Cookies:</strong> We use Session Cookies to operate our Service.</li>
                //             <li><strong>Preference Cookies:</strong> We use Preference Cookies to remember your preferences and various settings.</li>
                //             <li><strong>Security Cookies:</strong> We use Security Cookies for security purposes.</li>
                //             <li><strong>Advertising Cookies:</strong> Advertising Cookies are used to serve you with advertisements that may be relevant to you and your interests.</li>
                //         </ul>`
                //     ]
                // },
                // {
                //     title: `Analytics`,
                //     contents: [
                //         `We may use third-party Service Providers to monitor and analyze the use of our Service.`,
                //     ]
                // },
                // {
                //     title: `CI/CD Tools`,
                //     contents: [
                //         `We may use third-party Service Providers to automate the development process of our Service.`,
                //     ]
                // },
                // {
                //     title: `Amendments`,
                //     contents: [
                //         `We may update this policy from time to time by publishing a new version on our software. You should check this page occasionally to ensure you understand any changes to this policy.`,
                //     ]
                // },
                // {
                //     title: `Your Rights`,
                //     contents: [
                //         `You may instruct us to provide you with any personal information we hold about you. Provision of such information will be subject to the supply of appropriate evidence of your identity.`,
                //     ]
                // },
                // {
                //     title: `Third Party Websites`,
                //     contents: [
                //         `Our software includes hyperlinks to, and details of, third-party websites. We have no control over and are not responsible for the privacy policies and practices of third parties.`,
                //     ]
                // },
                // {
                //     title: `Payments`,
                //     contents: [
                //         `We may provide paid products and/or services within the Platform. In that case, we use third-party services for payment processing (e.g. payment processors). We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.`,
                //     ]
                // },
                // {
                //     title: `Children's Privacy`,
                //     contents: [
                //         `Our Platform is not intended for use by children under the age of 18 (“Child” or “Children”).`,
                //         `We do not knowingly collect personally identifiable information from Children under 18. If you become aware that a Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from Children without verification of parental consent, we take steps to remove that information from our servers.`,
                //     ]
                // },
                // {
                //     title: `Updating Information`,
                //     contents: [
                //         `Please let us know by contacting us via email if the personal information that we hold about you needs to be corrected or updated.`
                //     ]
                // },
                // {
                    
                //     title: `Retention of Data`,
                //     contents: [
                //         `We will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.`,
                //         `We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.`,
                //     ]
                // },
                // {
                //     title: `Transfer of Data`,
                //     contents: [
                //         `Your information, including Personal Data, may be transferred to — and maintained on - computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.`,
                //         `If you are located outside the Philippines and choose to provide information to us, please note that we transfer the data, including Personal Data, to the Philippines and process it there.`,
                //         `Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.`,
                //         `Skilltech Inc. will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.`,
                //     ]
                // },
                // {
                //     title: `Changes to This Privacy Policy`,
                //     contents: [
                //         `We reserve the right to modify our Privacy Policy at any time. It is your responsibility to review our Privacy Policy page periodically. Your continued use of the Platform following the posting of any changes to the Privacy Policy constitutes acceptance of those changes.`,
                //         `By continuing to access or use our App after any revisions become effective, you agree to be bound by the revised Privacy Policy. If you do not agree to the new Privacy Policy, you are no longer authorized to use the App.`
                //     ]
                // },
                // {
                //     title: `Contact`,
                //     contents: [
                //         `If you have any questions about this Privacy Policy or our treatment of your personal information, please write to us by email to support@skilltech.ph.`
                //     ]
                // }
            ]
        }
    }
}
</script>

<style scoped>
h1 {
    @apply my-4 text-center text-3xl font-extrabold text-warm-gray-900 dark:text-true-gray-300 sm:text-4xl
}

h2 {
    @apply mt-1 text-base text-center text-warm-gray-900 dark:text-true-gray-300 sm:text-lg mx-auto
}

dl {
    @apply mt-6 space-y-6 divide-y divide-warm-gray-200 dark:divide-warm-gray-700 max-w-3xl mx-auto
}

p {
    @apply mt-4 text-sm text-warm-gray-500 max-w-3xl mx-auto
}

p a{
    @apply text-yellow-500
}

section{
    @apply dark:bg-true-gray-800 bg-white py-24
}

</style>