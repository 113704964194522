// CouponService.js
import { v4 } from 'uuid'
// import { db, auth } from '@/firebase/config.js'
import firebase from "@/firebase/config";
// import "firebase/auth";
// import "firebase/firestore";
const db = firebase.firestore();
const auth = firebase.auth();

const CouponStatus = {
    ACTIVE: "active",
    ASSIGNED: "assigned",
    USED: "used",
    DEACTIVATED: "deactivated"
};

/**
 * Retrieves the details of a specific coupon using its unique code.
 * @param {String} couponId - The coupon id of the coupon to read.
 * 
 * Notes by @john :
 *  ✅ Done with function
 */
function readCoupon(couponId) {
    return new Promise((resolve, reject) => {
        console.log(`%c🎫 ----------- readCoupon 🏁 -----------`, 'background: #222; color: #bada55');
        console.log(`🎫 Reading coupon from the DB with ID: "${couponId}"...`);

        const couponRef = db.collection("coupons").doc(couponId);
        couponRef.get().then((docSnap) => {
            if (!docSnap.exists) {
                console.error(`The coupon with ID "${couponId}" was not found.`);
                reject(new Error(`The coupon with ID "${couponId}" was not found.`));
            } else {
                console.log(`🎫 Coupon with ID "${couponId}" was successfully found: `, docSnap.data());
                resolve({
                    id: docSnap.id,
                    data: docSnap.data()
                });
            }
        }).catch((error) => {
            console.error(`🎫 An error occurred while retrieving the coupon with ID "${couponId}" from the DB.`, error);
            reject(error);
        });
    });
}


async function useCoupon(coupon, user) {
    return new Promise(async (resolve, reject) => {
        try {

            if (checkCouponIfExpired(coupon)) throw new Error(`The coupon already expired.`)
            if (coupon.data.status === CouponStatus.USED) throw new Error(`The coupon is already in use.`)

            if ((coupon.data.status === CouponStatus.ACTIVE || coupon.data.status === CouponStatus.ASSIGNED)) {
                let updatedCouponData = {
                    status: CouponStatus.USED, // set back to active
                    usedAt: new Date(),
                    usedByEmail: user.email,
                    usedById: user.id
                }
                const docRef = db.collection("coupons").doc(coupon.id);
                await docRef.update(updatedCouponData);
                await recordCouponAction({
                    actionType: 'useCoupon',
                    details: `Coupon was used.`,
                    couponId: coupon.id,
                    actorId: auth.currentUser.uid,
                    actorEmail: auth.currentUser.email,
                    timestamp: new Date(),
                    beforeState: {
                        status: coupon.data.status,
                        usedAt: coupon.data.usedAt,
                        usedById: coupon.data.usedById,
                        usedByEmail: coupon.data.usedByEmail,
                    },
                    afterState: {
                        status: updatedCouponData.status,
                        usedAt: updatedCouponData.usedAt,
                        usedById: updatedCouponData.usedById,
                        usedByEmail: updatedCouponData.usedByEmail,
                    },
                });
                resolve(true)
                // resolve(await readCoupon(docRef.id))
            } else {
                throw new Error(`The coupon can only be used if it is active, assigned and not expired.`)
            }
        } catch (error) {
            console.error(`🎫 An error occurred while using coupon with ID "${coupon.id}".`, error)
            reject(error)
        }
    })
}

async function checkCouponValidity(coupon) {
    return new Promise(async (resolve, reject) => {
        try {
            console.log(`🎫 Checking validity of coupon with code: "${coupon.data.code}".`)
            if (checkCouponIfExpired(coupon)) throw new Error(`The coupon is expired.`)
            if (coupon.data.status === CouponStatus.USED) throw new Error(`The coupon is already in use.`)
            if (coupon.data.status === CouponStatus.DEACTIVATED) throw new Error(`The coupon is not active.`)
            console.log(`🎫 Coupon with code: "${coupon.data.code}" is valid.`)
            resolve(true)
        } catch (error) {
            console.error(`🎫 An error occurred while checking validity of coupon withe code: "${coupon.data.code}".`, error)
            reject(error)
        } finally {
            /**
             * Removing this to cut cost. Only record
             * log when a coupon is used.
             */
            // recordCouponAction({
            //     actionType: 'checkCouponValidity',
            //     details: 'A coupon code was checked if it\'s valid.',
            //     couponId: coupon.id,
            //     actorId: auth.currentUser.uid,
            //     actorEmail: auth.currentUser.email,
            //     timestamp: new Date(),
            //     beforeState: {
            //         code: coupon.data.code
            //     },
            //     afterState: null,
            // })
        }
    })
}

async function checkCouponCodeIfExists(couponCode) {
    return new Promise(async (resolve, reject) => {

        couponCode = couponCode.toUpperCase();
        console.log(`🎫 Checking if coupon with code: "${couponCode}" exists.`);
        db.collection('coupons').where('code', '==', couponCode).get()
            .then((existingCouponSnapshot) => {
                if (existingCouponSnapshot.empty) {
                    console.warn(`🎫 No coupon with code: "${couponCode}" was found.`);
                    // throw new Error(`The coupon is not found.`);
                    reject(new Error(`The coupon is not found.`));
                } else {
                    console.log(`🎫 Coupon with code: "${couponCode}" was found.`);
                    resolve({
                        id: existingCouponSnapshot.docs[0].id,
                        data: existingCouponSnapshot.docs[0].data()
                    });
                }
            })
            .catch((error) => {
                console.error(`🎫 An error occurred while using coupon with ID "${couponCode}".`, error);
                reject(error);
            })
            .finally(() => {
                /**
                 * Removing this to cut cost. Only record
                 * log when a coupon is used.
                 */
                // recordCouponAction({
                //     actionType: 'checkCouponCodeIfExists',
                //     details: 'A coupon code was checked if it exists.',
                //     couponId: null,
                //     actorId: auth.currentUser.uid,
                //     actorEmail: auth.currentUser.email,
                //     timestamp: new Date(),
                //     beforeState: {
                //         code: couponCode
                //     },
                //     afterState: null,
                // })
            });
    })
}

// utility 
function checkCouponIfExpired(coupon) {
    // const currentDate = new Date()
    // const currentDateTs = Timestamp.fromDate(currentDate)
    // const expirationDateTs = coupon.data.expiryDate
    // return (currentDateTs.seconds > expirationDateTs.seconds)

    const currentDate = new Date();
    const expirationDate = coupon.data.expiryDate.toDate(); // Convert Timestamp to Date
    return currentDate > expirationDate; // Compare Date objects directly
}

/**
 * Logs any action taken on a coupon for audit purposes.
 * @param {Object} actionDetails - The details of the action taken on the coupon.
 * 
 * Notes by @john :
 *  ✅ Done with function
 */
function recordCouponAction(actionDetails) {
    return new Promise(async (resolve, reject) => {
        console.log(`%c🎫 ----------- recordCouponAction 🏁 -----------`, 'background: #222; color: #bada55');
        console.log(`🎫 Recording coupon action for audit purposes.`);
        db.collection('coupon_actions').add(actionDetails)
            .then(() => {
                console.log(`🎫 Coupon action has been recorded successfully.`);
                resolve(true);
            })
            .catch((error) => {
                console.error(`🎫 An error occurred while recording the coupon action: "${actionDetails.couponCode}".`, error);
                reject(error);
            });
    })
}

function convertTimestampToDate(t) {

    if (!t) return 'NA'
    // Returned Format: 2023-11-29 08:53 PM
    // const timestamp = new Timestamp(t.seconds, t.nanoseconds)
    // const date = timestamp.toDate()
    
    // Convert Timestamp to Date
    console.log(t)
    const date = t.toDate();
    
    const year = date.getFullYear()
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const month = monthNames[date.getMonth()] // Use this for month names
    // const month = String(date.getMonth() + 1).padStart(2, '0'); // Use this if months will be 0-based formatted
    const day = String(date.getDate()).padStart(2, '0')
    const hours = date.getHours()
    const minutes = String(date.getMinutes()).padStart(2, '0')
    const seconds = String(date.getSeconds()).padStart(2, '0')

    let formattedHours = hours % 12 || 12 // Convert to 12-hour format
    formattedHours = String(formattedHours).padStart(2, '0') // Add leading zero if needed
    const ampm = hours >= 12 ? 'PM' : 'AM'

    return `${year}-${month}-${day} ${formattedHours}:${minutes}:${seconds} ${ampm}`
}

// Export the functions to be available for import in other modules
export {
    // crudl interface
    readCoupon,
    // log audits
    recordCouponAction,
    // coupon status functions
    useCoupon,
    // utility functions
    checkCouponIfExpired,
    checkCouponCodeIfExists,
    checkCouponValidity,
    convertTimestampToDate
}
